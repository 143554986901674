import style from './gitinput.module.css';
import {useEffect, useState} from "react";

function GitInput({onSubmit}) {
    const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i
    const [urlInput, setUrlInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        let timeoutId;

        if (errorMessage && errorMessage !== '') {
            timeoutId = setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [errorMessage]);

    function handleChange(event) {
        setUrlInput(event.target.value);
    }

    function checkURL(url) {
        return url !== '' && urlRegex.test(url);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (!checkURL(urlInput)) {
            setErrorMessage('You must write valid URL.');
            return;
        }
        onSubmit(urlInput);
    }

    return (
        <form className={style.form} onSubmit={handleSubmit}>
            { errorMessage && errorMessage !== '' && (
                <p style={{ color: 'red' }}>{errorMessage}</p>
            )}
            <input className={style.input} value={urlInput} onChange={handleChange} type="url" name="url" id="url" required/>
            <label className={style.label} htmlFor="url">GIT URL</label>
        </form>
    );
}

export default GitInput;