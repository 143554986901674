import style from './App.module.css';
import GitInput from "./components/GitInput";
import {useState, useRef} from "react";

function App() {

    const socketRef = useRef(null);
    const [gitUrl, setGitURL] = useState('');
    const [renderStatus, setRenderStatus] = useState({});
    const [downloadUrl, setDownloadUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function formatSize(size) {
        const units = ['o', 'Ko', 'Mo', 'Go', 'To'];
        let unitIndex = 0;
        let formattedSize = size;

        while (formattedSize >= 1024 && unitIndex < units.length - 1) {
            formattedSize /= 1024;
            unitIndex++;
        }

        return `${formattedSize.toFixed(2)}${units[unitIndex]}`;
    }

    function startSocketClient(url) {
        if (socketRef.current)
            return;
        const socket = socketRef.current = new WebSocket(`wss://${window.location.host}/ws`);
        socket.onopen = (event) => {
            socket.send(JSON.stringify({action: 'start', url: url}));
        };
        socket.onmessage = (event) => {
            let message = JSON.parse(event.data);

            if ('step' in message) {
                switch (message.step.toLowerCase()) {
                    case 'crash':
                        setErrorMessage("Internal server error.");
                        if (socketRef.current.readyState === WebSocket.CONNECTING) {
                            socketRef.current.close();
                            socketRef.current = undefined;
                        }
                        break;
                    case 'end':
                        if ('fileName' in message)
                            setDownloadUrl(`/video/${message.fileName}`)
                        if (socketRef.current.readyState === WebSocket.CONNECTING) {
                            socketRef.current.close();
                            socketRef.current = undefined;
                        }
                        break;
                }
            }
            setRenderStatus(message);
        };
        socket.onclose = (event) => {
            socketRef.current = undefined;
            if (event.code === 1000)
                return;
            else {
                if (renderStatus && 'step' in renderStatus && renderStatus.step === 'crash')
                    setErrorMessage('Internal serveur error: ' + ('title' in renderStatus && renderStatus !== '') ? renderStatus.title : 'internal');
                setErrorMessage(`Connection closed (${event.code}).`)
            }
            setGitURL('');
        };

        socket.onerror = (event) => {
            console.log('WebSocket error: ', event);
            console.log(`WebSocket encountered an error: ${event.message}`);
        }
    }

    function onGitSubmit(url) {
        setGitURL(url);
        setErrorMessage('');
        startSocketClient(url);
    }

    function onResetClick() {
        setDownloadUrl('')
        setRenderStatus({})
        setGitURL('')
        setErrorMessage('')
    }

    return (
        <div className={style.App}>
            <div className={style.App__Display}>
                { gitUrl && gitUrl !== '' ?
                    (
                        <div>
                            {renderStatus && 'title' in renderStatus && 'fileSize' in renderStatus && (
                                <p style={{color: 'gray'}}>{renderStatus.title} (<strong style={{color: 'gold'}}>{formatSize(renderStatus.fileSize)}</strong>)</p>
                            )}
                            {renderStatus && 'percent' in renderStatus && renderStatus.percent >= 0 && (
                                <progress value={renderStatus.percent} max={100}></progress>
                            )}
                        </div>
                    ) : (
                        <GitInput onSubmit={onGitSubmit}/>
                    )
                }
            </div>
            <div style={{display: 'flex', 'flex-direction': 'column'}}>
                {downloadUrl && (
                    <div style={{display: 'flex', 'flex-direction': 'column'}}>
                        <a href={downloadUrl} download="video.mp4">
                            Télécharger le fichier
                        </a>
                        <button onClick={onResetClick}>Reset !</button>
                    </div>
                )}
                {errorMessage && errorMessage !== '' && (
                    <p style={{color: 'red'}}>{errorMessage}</p>
                )}
            </div>
        </div>
    );
}

export default App;
